<template>
  <div class="mt-4">
    <router-view/>
  </div>
</template>
<script>
    export default {
    name: 'procesosJuridicosIndex'
    }
</script>

<style scoped>

</style>
